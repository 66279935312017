import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, Form, Modal, Row, Tab, Nav, Table } from "react-bootstrap";
import Moment from 'react-moment';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { audio } from "../../../redux/actions";
import PAGINATION from '../../../components/common/pagination';
import { useForm } from "react-hook-form";
import play_icon from '../../../assets/images/icons/play.svg';
import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import video from '../../../assets/images/icons/video.png';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { PATCH } from '../../../utils/axios.util';
import useAssignmentsQueue from '../../../hooks/useAssignmentsQueue';

function CoordinatorAssignments(props) {

    const [approveModalShow, setApproveModalShow] = useState(false);
    const [disApproveModalShow, setDisApproveModalShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [approveId, setApproveId] = useState('');
    const [disApproveId, setDisApproveId] = useState('');
    const [modalAssignmentsDetailsShow, setShow] = useState(false);
    const handleAssignmentsDetailModalClose = () => setShow(false);
    const handleAssignmentsDetailShow = () => setShow(true);
    const navigate = useNavigate()
    const assignments = useAssignmentsQueue()
    const dispatch = useDispatch()
    const {
        formState: { errors: errors2 },
    } = useForm();
    const itemsPerPage = 10
    const [requestedOffset, setRequestedOffset] = useState(0);
    const [progressOffset, setProgressOffset] = useState(0);
    const [reviewOffset, setReviewOffset] = useState(0);
    const [completedOffset, setCompletedOffset] = useState(0);
    const [currentRequestedItems, setCurrentRequestedItems] = useState([])
    const [currentProgressItems, setCurrentProgressItems] = useState([])
    const [currentReviewItems, setCurrentReviewItems] = useState([])
    const [currentCompletedItems, setCurrentCompletedItems] = useState([])
    const [requestedPageCount, setRequestedPageCount] = useState(0);
    const [progressPageCount, setProgressPageCount] = useState(0);
    const [reviewPageCount, setReviewPageCount] = useState(0);
    const [completedPageCount, setCompletedPageCount] = useState(0);
    const [activePageNumber, setActivePageNumber] = useState('');
    const [descriptionModal, setdescriptionModal] = useState(false)
    const [descriptionValue, setdescriptionValue] = useState('')
    const handleApproveModalClose = () => setApproveModalShow(false);
    const handleDisApproveModalClose = () => setDisApproveModalShow(false);

    var isAssignmentsListingInitialized = useRef(true)

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')
    let defaultTabValue = 'requested'
    let currentPageNumValue = 0
    let initialPageValue = 0

    if (urlTab == 'progress') {
        defaultTabValue = 'progress'
    }
    if (urlTab == 'review') {
        defaultTabValue = 'review'
    }
    if (urlTab == 'completed') {
        defaultTabValue = 'completed'
    }

    if (urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [currentPageNum, setCurrentPageNum] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    const [defaultTab, setDefaultTab] = useState(defaultTabValue);

    useEffect(() => {

        if (Object.keys(assignments).length > 0 && isAssignmentsListingInitialized.current) {

            const requestedEndOffset = requestedOffset + itemsPerPage;
            const progressEndOffset = progressOffset + itemsPerPage;
            const reviewEndOffset = reviewOffset + itemsPerPage;
            const completedEndOffset = completedOffset + itemsPerPage;
            let requestedChunk = []
            let progressChunk = []
            let reviewChunk = []
            let completedChunk = []
            let requestedPageCount = Math.ceil(assignments.list.requested.length / itemsPerPage);
            let progressPageCount = Math.ceil(assignments.list.progress.length / itemsPerPage);
            let reviewPageCount = Math.ceil(assignments.list.review.length / itemsPerPage);
            let completedPageCount = Math.ceil(assignments.list.completed.length / itemsPerPage);

            if (assignments.list.requested.length > 0) {
                requestedChunk = assignments.list.requested.slice(requestedOffset, requestedEndOffset)
            }
            if (assignments.list.progress.length > 0) {
                progressChunk = assignments.list.progress.slice(progressOffset, progressEndOffset)
            }
            if (assignments.list.review.length > 0) {
                reviewChunk = assignments.list.review.slice(reviewOffset, reviewEndOffset)
            }
            if (assignments.list.completed.length > 0) {
                completedChunk = assignments.list.completed.slice(completedOffset, completedEndOffset)
            }

            setRequestedPageCount(requestedPageCount)
            setProgressPageCount(progressPageCount)
            setReviewPageCount(reviewPageCount)
            setCompletedPageCount(completedPageCount)
            setCurrentRequestedItems(requestedChunk)
            setCurrentProgressItems(progressChunk)
            setCurrentReviewItems(reviewChunk)
            setCurrentCompletedItems(completedChunk)
            isAssignmentsListingInitialized.current = false

        }
        // console.log(assignments);
        
    }, [assignments, currentRequestedItems, currentProgressItems, currentReviewItems, currentCompletedItems, requestedOffset, progressOffset, reviewOffset, completedOffset, itemsPerPage]);

    const handlePageClick = (event, currentTab) => {

        let items = []

        if (currentTab == 'requested') {
            items = assignments.list.requested
        }
        if (currentTab == 'progress') {
            items = assignments.list.progress
        }
        if (currentTab == 'review') {
            items = assignments.list.review
        }
        if (currentTab == 'completed') {
            items = assignments.list.completed
        }

        const newOffset = (event.selected * itemsPerPage) % items.length;

        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        if (currentTab == 'requested') {
            setRequestedPageCount(pageCount)
            setCurrentRequestedItems(chunk)
            setRequestedOffset(newOffset);
        }
        if (currentTab == 'progress') {
            setProgressPageCount(pageCount)
            setCurrentProgressItems(chunk)
            setProgressOffset(newOffset);
        }
        if (currentTab == 'review') {
            setReviewPageCount(pageCount)
            setCurrentReviewItems(chunk)
            setReviewOffset(newOffset);
        }
        if (currentTab == 'completed') {
            setCompletedPageCount(pageCount)
            setCurrentCompletedItems(chunk)
            setCompletedOffset(newOffset);
        }

    };

    const viewAssignment = (elem) => {

        if (elem.status == 'pending' || elem.status == 'progress' || elem.status == 'interrupted' || elem.status == 'error') {
            if (elem.type == 'audio') {
                dispatch(audio(elem.original_file_path, elem.title, elem.category_title, elem.sub_category_title))
            } else if (elem.type == 'document') {
                window.open(elem.original_file_path, '_blank');
            } else {
                navigate('/coordinator/assignments/' + elem.assignment_id)
            }
        } else {

            navigate('/coordinator/assignments/' + elem.assignment_id)
        }

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
    }

    const closeDescriptionModal = () => {
        setdescriptionModal(false)
        setdescriptionValue("")
    }

    const openDescriptionModal = (data) => {
        if (data?.length > 0) {
            setdescriptionValue(data)
            setdescriptionModal(true)
        }
    }

    const assignStudents = (elem) => {
        let activeItemSelector = `#fdgdfg-tabpane-completed ul li.page-item.active a`
        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        navigate(`/coordinator/assignments/${elem.assignment_id}/assign-students?tab=completed&page=${activePageNumber}`)
    }

    const assignPage = (elem) => {
        let activeItemSelector = `#fdgdfg-tabpane-requested ul li.page-item.active a`
        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        navigate(`/coordinator/assignments/${elem.assignment_id}/assign/interpreter?tab=requested&page=${activePageNumber}`)
    }

    return (
        <>

            <h2 className="page_hdng">Assignments</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card" onClick={handleAssignmentsDetailShow}>
                        <h4>{Object.keys(assignments).length > 0 ? assignments.requested_count : 0}</h4>
                        <p className='text-capatilize'>requested</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.review_count : 0}</h4>
                        <p className='text-capatilize'>under Review </p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.completed_count : 0}</h4>
                        <p className='text-capatilize'>completed</p>
                    </div>
                </Col>
                <Col sm="12" md="6" className='col-lg-auto'>
                    <div className="assignments_card">
                        <div className='my-2 d-flex align-items-center justify-content-between' >
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={ntpd_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.documents_count : 0}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={mic_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.audios_count : 0}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={video_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.videos_count : 0}</span>
                            </div>
                        </div>
                        <p className='text-capatilize'>Assignments Formats</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="fixed_tab">
                        <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                            <Row className='justify-content-between'>
                                <Col className='col-auto'>
                                    <Nav variant="fixed_pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="requested" onClick={() => changeTab('requested')}>Requested</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="progress" onClick={() => changeTab('progress')}>In Progress</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="review" onClick={() => changeTab('review')}>Under Review</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="completed" onClick={() => changeTab('completed')}>Completed</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col className='col-auto'>
                                    <Row>
                                        <Col className='col-auto'>
                                            <div className='d-flex justify-content-end'>
                                                <NavLink to="request-assignment" className="btn btn-success btn-md px-4 ms-2">Create Assignment</NavLink>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                            <Tab.Content>
                                <Tab.Pane eventKey="requested">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Assignments Name</th>
                                                <th className='text-center'>Date & Time</th>
                                                {/* <th className='text-center'>Duration </th> */}
                                                <th className='text-center'>Format</th>
                                                <th colSpan={2} width="50">Interpreter</th>
                                                <th className='text-center'>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(assignments).length > 0 && assignments.list.requested.length > 0 &&
                                                currentRequestedItems.map((elem, index) => {

                                                    let durationSplit = elem.duration.split(':');
                                                    let hours = parseInt(durationSplit[0])
                                                    let mins = parseInt(durationSplit[1])
                                                    let secs = parseInt(durationSplit[2])

                                                    var duration = ''
                                                    var assignmentIcon = ''

                                                    if (hours > 0) {
                                                        duration += `${hours}h`
                                                    }
                                                    if (mins > 0) {
                                                        duration += ` ${mins}m`
                                                    }
                                                    if (secs > 0) {
                                                        duration += ` ${secs}s`
                                                    }
                                                    duration = duration.trim()

                                                    if (elem.type == 'video') {
                                                        assignmentIcon = video_arrow
                                                    }
                                                    if (elem.type == 'audio') {
                                                        assignmentIcon = mic_arrow
                                                    }
                                                    if (elem.type == 'document') {
                                                        assignmentIcon = ntpd_arrow
                                                    }

                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <NavLink to={'/coordinator/assignments/' + elem.id} >
                                                                    <h5>{elem.title}</h5>
                                                                </NavLink>
                                                                <p>
                                                                    <span className='text_white_color_half'>{elem.division_name},</span>
                                                                    <span className='text_brand_color2'>{elem.category_title}</span>
                                                                </p>
                                                            </td>
                                                            <td className='text-center'>
                                                                <h5>
                                                                    <Moment format="MM/DD/YY">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </h5>
                                                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                                                <span className='text_brand_color3'>
                                                                    <Moment format="hh:mm A">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </span>
                                                            </td>
                                                            {/*  <td className='text-center'>
                                                            <h5>{duration}</h5>
                                                        </td> */}
                                                            <td className='text-center'>
                                                                <img src={assignmentIcon} />
                                                            </td>
                                                            {elem.interpreter &&
                                                                <>
                                                                    <td width="50">
                                                                        <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                                    </td>
                                                                    <td>
                                                                        <h5>{elem.interpreter.full_name}</h5>
                                                                    </td>
                                                                </>
                                                            }
                                                             {elem.status == "pending" &&
                                                                <>
                                                                    <td className='text-center'>
                                                                        

                                                                                <h5>--</h5>
                                                                         
                                                                    </td>
                                                                    <td className='text-center'>
                                                                    </td>
                                                                </>
                                                            }
                                                            {elem.status == "requested" &&
                                                                <>
                                                                    <td className='text-center'>
                                                                        {
                                                                            elem?.interPretList.map(item=>(

                                                                                <h5>{item?.first_name}, </h5>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                    </td>
                                                                </>
                                                            }
                                                            <td className='text-center text_brand_color6 text-capitalize'>{elem.status}</td>
                                                            <td className='text-center'>
                                                                {elem.assign_interpreter === 1 && elem.interpreter_id &&
                                                                    <>
                                                                        <Button variant='success' size='sm' disabled={true} >Interpreter Assigned</Button>&nbsp;|&nbsp;
                                                                    </>
                                                                }
                                                                {!elem.interpreter_id && elem.status === "pending" ? 
                                                                   ( <>
                                                                        <Button variant='success' size='sm' onClick={() => assignPage(elem)} >Assign Interpreter</Button>&nbsp;|&nbsp;
                                                                    </>) : (
                                                                         <>
                                                                         <Button variant='success' size='sm' 
                                                                        //  onClick={() => assignPage(elem)} 
                                                                          disabled={true}
                                                                         >Assigned</Button>&nbsp;|&nbsp;
                                                                     </>
                                                                    )
                                                                }
                                                                <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {Object.keys(assignments).length > 0 && assignments.list.requested.length === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="9" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(assignments).length > 0 && assignments.list.requested.length > 0 &&
                                        <PAGINATION allItems={assignments.list.requested} chunkedItems={currentRequestedItems} handlePageClick={event => handlePageClick(event, 'requested')} pageCount={requestedPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="progress">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Assignments Name</th>
                                                <th className='text-center'>Date & Time</th>
                                                {/* <th className='text-center'>Duration </th> */}
                                                <th className='text-center'>Format & Description</th>
                                                <th colSpan={2} width="50">Interpreter</th>
                                                <th className='text-center'>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(assignments).length > 0 && assignments.list.progress.length > 0 &&
                                                currentProgressItems.map((elem, index) => {

                                                    let durationSplit = elem.duration.split(':');
                                                    let hours = parseInt(durationSplit[0])
                                                    let mins = parseInt(durationSplit[1])
                                                    let secs = parseInt(durationSplit[2])

                                                    var duration = ''
                                                    var assignmentIcon = ''
                                                    let fontColor = 'text_brand_color3'

                                                    if (elem.status == 'disapproved') {
                                                        fontColor = 'text_brand_color7'
                                                    }

                                                    if (hours > 0) {
                                                        duration += `${hours}h`
                                                    }
                                                    if (mins > 0) {
                                                        duration += ` ${mins}m`
                                                    }
                                                    if (secs > 0) {
                                                        duration += ` ${secs}s`
                                                    }
                                                    duration = duration.trim()

                                                    if (elem.type == 'video') {
                                                        assignmentIcon = video_arrow
                                                    }
                                                    if (elem.type == 'audio') {
                                                        assignmentIcon = mic_arrow
                                                    }
                                                    if (elem.type == 'document') {
                                                        assignmentIcon = ntpd_arrow
                                                    }

                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <NavLink to={'/coordinator/assignments/' + elem.id} >
                                                                    <h5>{elem.title}</h5>
                                                                </NavLink>
                                                                <p>
                                                                    <span className='text_white_color_half'>{elem.division_name},</span>
                                                                    <span className='text_brand_color2'>{elem.category_title}</span>
                                                                </p>
                                                            </td>
                                                            <td className='text-center'>
                                                                <h5>
                                                                    <Moment format="MM/DD/YY">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </h5>
                                                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                                                <span className='text_brand_color3'>
                                                                    <Moment format="hh:mm A">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </span>
                                                            </td>
                                                            {/* <td className='text-center'>
                                                        <h5>{duration}</h5>
                                                    </td> */}
                                                            <td className='text-center'>
                                                                <a className='assignment-inner'><img src={assignmentIcon} onClick={() => openDescriptionModal(elem.description)} /></a>
                                                            </td>
                                                            {elem.interpreter &&
                                                                <>
                                                                    <td width="50">
                                                                        <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                                    </td>
                                                                    <td>
                                                                        <h5>{elem.interpreter.full_name}</h5>
                                                                    </td>
                                                                </>
                                                            }
                                                            {!elem.interpreter &&
                                                                <>
                                                                    <td className='text-center'>
                                                                        <h5>--</h5>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                    </td>
                                                                </>
                                                            }
                                                            <td className={`text-center ${fontColor} text-capitalize`}>{elem.status}</td>
                                                            <td className='text-center'>
                                                                <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {Object.keys(assignments).length > 0 && assignments.list.progress.length === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="9" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(assignments).length > 0 && assignments.list.progress.length > 0 &&
                                        <PAGINATION allItems={assignments.list.progress} chunkedItems={currentProgressItems} handlePageClick={event => handlePageClick(event, 'progress')} pageCount={progressPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="review">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Assignments Name</th>
                                                <th className='text-center'>Date & Time</th>
                                                <th className='text-center'>Duration </th>
                                                <th className='text-center'>Format</th>
                                                <th colSpan={2} width="50">Interpreter</th>
                                                <th className='text-center'>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(assignments).length > 0 && assignments.list.review.length > 0 &&
                                                currentReviewItems.map((elem, index) => {

                                                    let durationSplit = elem.duration.split(':');
                                                    let hours = parseInt(durationSplit[0])
                                                    let mins = parseInt(durationSplit[1])
                                                    let secs = parseInt(durationSplit[2])

                                                    var duration = ''
                                                    var assignmentIcon = ''

                                                    if (hours > 0) {
                                                        duration += `${hours}h`
                                                    }
                                                    if (mins > 0) {
                                                        duration += ` ${mins}m`
                                                    }
                                                    if (secs > 0) {
                                                        duration += ` ${secs}s`
                                                    }
                                                    duration = duration.trim()

                                                    if (elem.type == 'video') {
                                                        assignmentIcon = video_arrow
                                                    }
                                                    if (elem.type == 'audio') {
                                                        assignmentIcon = mic_arrow
                                                    }
                                                    if (elem.type == 'document') {
                                                        assignmentIcon = ntpd_arrow
                                                    }

                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <NavLink to={'/coordinator/assignments/' + elem.id} >
                                                                    <h5>{elem.title}</h5>
                                                                </NavLink>
                                                                <p>
                                                                    <span className='text_white_color_half'>{elem.division_name},</span>
                                                                    <span className='text_brand_color2'>{elem.category_title}</span>
                                                                </p>
                                                            </td>
                                                            <td className='text-center'>
                                                                <h5>
                                                                    <Moment format="MM/DD/YY">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </h5>
                                                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                                                <span className='text_brand_color3'>
                                                                    <Moment format="hh:mm A">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </span>
                                                            </td>
                                                            {/* <td className='text-center'>8h30s</td> */}
                                                            <td className='text-center'>
                                                                <h5>{duration}</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                                <img src={assignmentIcon} />
                                                            </td>
                                                            {elem.interpreter &&
                                                                <>
                                                                    <td width="50">
                                                                        <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                                    </td>
                                                                    <td>
                                                                        <h5>{elem.interpreter.full_name}</h5>
                                                                    </td>
                                                                </>
                                                            }
                                                            {!elem.interpreter &&
                                                                <>
                                                                    <td className='text-center'>
                                                                        <h5>--</h5>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                    </td>
                                                                </>
                                                            }
                                                            <td className='text-center text_brand_color3 text-capitalize'>{elem.status}</td>
                                                            <td className='text-center'>
                                                                <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {Object.keys(assignments).length > 0 && assignments.list.review.length === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="10" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(assignments).length > 0 && assignments.list.review.length > 0 &&
                                        <PAGINATION allItems={assignments.list.review} chunkedItems={currentReviewItems} handlePageClick={event => handlePageClick(event, 'review')} pageCount={reviewPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="completed">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Assignments Name</th>
                                                <th className='text-center'>Date & Time</th>
                                                <th className='text-center'>Duration </th>
                                                <th className='text-center'>Format</th>
                                                <th colSpan={2} width="50">Interpreter</th>
                                                <th className='text-center'>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(assignments).length > 0 && assignments.list.completed.length > 0 &&
                                                currentCompletedItems.map((elem, index) => {

                                                    let durationSplit = elem.duration.split(':');
                                                    let hours = parseInt(durationSplit[0])
                                                    let mins = parseInt(durationSplit[1])
                                                    let secs = parseInt(durationSplit[2])

                                                    var duration = ''
                                                    var assignmentIcon = ''

                                                    if (hours > 0) {
                                                        duration += `${hours}h`
                                                    }
                                                    if (mins > 0) {
                                                        duration += `${mins}m`
                                                    }
                                                    if (secs > 0) {
                                                        duration += `${secs}s`
                                                    }
                                                    duration = duration.trim()

                                                    if (elem.type == 'video') {
                                                        assignmentIcon = video_arrow
                                                    }
                                                    if (elem.type == 'audio') {
                                                        assignmentIcon = mic_arrow
                                                    }
                                                    if (elem.type == 'document') {
                                                        assignmentIcon = ntpd_arrow
                                                    }

                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <NavLink to={'/coordinator/assignments/' + elem.id} >
                                                                    <h5>{elem.title}</h5>
                                                                </NavLink>
                                                                <p>
                                                                    <span className='text_white_color_half'>{elem.division_name},</span>
                                                                    <span className='text_brand_color2'>{elem.category_title}</span>
                                                                </p>
                                                            </td>
                                                            <td className='text-center'>
                                                                <h5>
                                                                    <Moment format="MM/DD/YY">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </h5>
                                                                {/* <span className='text_brand_color3'>09:00AM</span> */}
                                                                <span className='text_brand_color3'>
                                                                    <Moment format="hh:mm A">
                                                                        {elem.created_at}
                                                                    </Moment>
                                                                </span>
                                                            </td>
                                                            {/* <td className='text-center'>8h30s</td> */}
                                                            <td className='text-center'>
                                                                <h5>{duration}</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                                <img src={assignmentIcon} />
                                                            </td>
                                                            {elem.interpreter &&
                                                                <>
                                                                    <td width="50">
                                                                        <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                                    </td>
                                                                    <td>
                                                                        <h5>{elem.interpreter.full_name}</h5>
                                                                    </td>
                                                                </>
                                                            }
                                                            {!elem.interpreter &&
                                                                <>
                                                                    <td className='text-center'>
                                                                        <h5>--</h5>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                    </td>
                                                                </>
                                                            }
                                                            <td className='text-center text_brand_color2 text-capitalize'>{elem.status}</td>
                                                            <td className='text-center'>
                                                                <Button variant='success' size='sm' onClick={() => assignStudents(elem)} >Assign Students</Button>&nbsp;|&nbsp;
                                                                <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {Object.keys(assignments).length > 0 && assignments.list.completed.length === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="8" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(assignments).length > 0 && assignments.list.completed.length > 0 &&
                                        <PAGINATION allItems={assignments.list.completed} chunkedItems={currentCompletedItems} handlePageClick={event => handlePageClick(event, 'completed')} pageCount={completedPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>

                </Col>
            </Row>
            <Modal className="approve-modal" show={descriptionModal} onHide={closeDescriptionModal} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>Description</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                        <Form.Control as="textarea" rows="5" name="disapprove_message" value={descriptionValue} autoComplete="off" />

                        {errors2.disapprove_message?.message && <span className="error">{errors2.disapprove_message?.message}</span>}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CoordinatorAssignments;